<template>
<!--  注册专家-->
<div class="expert">
  <div class="expert_header">
    <div>
      注册状态: &nbsp;<a-radio-group default-value="all" style="margin-right: 40px"  @change="searchOk">
      <a-radio-button value="all">
        全部
      </a-radio-button>
      <a-radio-button :value="0">
        未认领
      </a-radio-button>
      <a-radio-button :value="1">
        已认领
      </a-radio-button>
    </a-radio-group>
      名称: &nbsp;
      <a-input placeholder="请输入" v-model="searchName" style="width: 180px;margin-right: 20px" @keyup.enter.native="searchBtn()"  />

      <a-button type="primary" @click="searchBtn" style="margin-left: 30px;margin-right: 10px">
        查询
      </a-button>
      <a-button @click="searchChange">
        重置
      </a-button>
    </div>
  </div>
  <div class="expert_table">
    <a-button @click="addBtn" type="dashed" style="min-width: 100%">
      新增
    </a-button>
    <a-table style="margin-bottom: 5px" :scroll="{ x: 1000 }" :showHeader="false"  :columns="columns"  :data-source="InfoTable" :pagination="false" rowKey="id"
    >
      <span slot="icon" slot-scope="text,item">
        <img :src="item.headImg" style="height: 50px;width: 50px" alt="">
      </span>
      <template slot="claimStatus" slot-scope="text, row, index">
        <div v-if="!row.claimStatus">未认领</div>
        <div v-else style="color: #79BF04">
          <div v-if="row.registerTel">          注册账号：{{row.registerTel.replace(/^(\d{3})\d{4}(\d+)/, '$1****$2')}}</div>
          <div v-if="row.registerTime">注册时间：{{ row.registerTime }}</div>
        </div>
      </template>
      <span slot="titles" slot-scope="text,item">
        <div >
          <div style="display: flex">
            <span style="font-weight: bold;width: 80px">{{item.name}}</span>&nbsp;&nbsp;
            <span>{{item.hospital}}&nbsp;&nbsp;{{item.hospitalOffice!==null?item.hospitalOffice:item.department}}&nbsp;&nbsp;{{item.job}}</span>
          </div>
          <div style="display: flex" v-if="item.tags!==null &&item.tags!==''">
            <span  v-for="tag in item.tag">
              <a-tag>{{tag}}</a-tag>
            </span>

          </div>
          <div style="display: flex" v-else>
            <a-tag >暂无标签</a-tag>
          </div>
        </div>
      </span>
      <span slot="time" slot-scope="text,item">
        <span>创建时间：{{item.createdTime}}</span>
        </br>
        <span>简介上次修改时间：{{item.upUpdatedTime ? item.upUpdatedTime:item.createdTime}}</span>
      </span>
      <span slot="open" slot-scope="text,item">
        <a-tag v-if="item.isConsultation===1" color="green">
          会诊专家
        </a-tag>
      </span>
      <span slot="operate" slot-scope="text,item">
<!--        <a style="margin-right: 8px"   @click="memberBtn(item)">{{item.memberId ===null? '绑定账号' :item.memberId ===''?'绑定账号':item.memberId ==='null'? '绑定账号':'查看账号'}}</a>-->
<!--        <a style="margin-right: 8px"  v-if="item.isConsultation===0" @click="meetAdd(item.id)">添加到会诊专家</a>-->
            <a style="margin-right: 8px" @click="editBtn(item)">编辑</a>
            <a style="margin-right: 8px" @click="resumeBtn(item)">简介</a>
            <a style="margin-right: 8px" @click="accessoryBtn(item.id)">附件</a>

        <a-dropdown >
          <a class="ant-dropdown-link" @click="e => e.preventDefault()">
            更多 <a-icon type="down" />
          </a>
          <a-menu slot="overlay" >
<!--            <a-menu-item key="1" @click="editBtn(item)">-->
<!--              编辑-->
<!--            </a-menu-item>-->
<!--            <a-menu-item key="2">-->
<!--              <a @click="accessoryBtn(item.id)">附件</a>-->
<!--            </a-menu-item>-->
            <a-menu-item v-if="item.memberId !== null && item.memberId !== '' && item.memberId !== 'null'" key="1"  @click="memberBtn(item)">
              {{item.memberId ===null? '绑定账号' :item.memberId ===''?'绑定账号':item.memberId ==='null'? '绑定账号':'查看账号'}}
            </a-menu-item>
<!--            <a-menu-item v-if="item.isConsultation===0" @click="meetAdd(item.id)" key="2">-->
            <a-menu-item v-if="item.isConsultation===0" key="2">
<!--              <a @click="meetAdd(item.id)">添加到会诊专家</a>-->
                <a-popconfirm
                    title="是否确认添加到会诊专家?"
                    ok-text="是"
                    cancel-text="否"
                    @confirm="meetAdd(item.id)"
                ><a>添加到会诊专家</a>
                </a-popconfirm>
            </a-menu-item>
            <!-- <a-menu-item key="3">
              <a-popconfirm
                  title="确定删除?"
                  ok-text="是"
                  cancel-text="否"
                  @confirm="delTitle(item.id)"
                  @cancel="cancel">
          <a>删除</a>
        </a-popconfirm>
            </a-menu-item> -->
    </a-menu>
  </a-dropdown>
      </span>

    </a-table>


    <div style="display: flex;justify-content: flex-end;">
      <a-pagination v-model="pagination.current"
                    :total="pagination.total"
                    @change="pageChange"/>
    </div>
  </div>

  <!-- 新增 -->
  <a-drawer
      title="新建"
      :width="600"
      :visible="addShow"
      :body-style="{ paddingBottom: '80px' }"
      @close="addClose()"
  >
    <a-form-model ref="addItem" :label-col="labelCol" :model="addForm" :rules="addRules" :wrapper-col="wrapperCol"  >
      <a-form-model-item label="姓名 " prop="name">
        <a-auto-complete @blur="nameBulr('addForm')" @change="nameChange($event, 'addForm')" v-model="addForm.name" placeholder="请输入" @search="handleSearch($event, 'addForm')">
          <template slot="dataSource">
            <a-select-option @click="changeClick(email, 'addForm')" v-for="(email, index) in result" :key="JSON.stringify({name: email.name, id: email.id})">
              {{ email.nameAndHospital }}
            </a-select-option>
          </template>
        </a-auto-complete>
        <!--          <a-input v-model="addForm.name" placeholder="请输入" />-->
      </a-form-model-item>
      <a-form-model-item label="头像1" prop="headImg">
          <div>
            <div style="display: flex">
              <div class="uploadImg">
                <input
                    id="inputClear"
                    ref="addImg"
                    accept="image/*"
                    style="width: 100%;height:100%;opacity: 0;"
                    type="file"
                    @change="uploadImg('addImg')"/>
              </div>
              <div class="upload_gutter">
                    <img
                        v-if="addForm.headImg"
                        :src="addForm.headImg"
                        alt=""
                        style="width: 100%;"
                        />
              </div>
            </div>
            <span style="font-size: xx-small">注：尺寸100*100，大小不超过500kb</span>
          </div>
        </a-form-model-item>
        <a-form-model-item label="头像2">
          <div>
            <div style="display: flex">
              <div class="uploadImg">
                <input
                    id="inputClear2"
                    ref="addImgRect"
                    accept="image/*"
                    style="width: 100%;height:100%;opacity: 0;"
                    type="file"
                    @change="uploadImg('addImgRect')"/>
              </div>
              <div class="upload_gutter2">
                <img
                    v-if="addForm.headImgRect"
                    :src="addForm.headImgRect"
                    alt=""
                    style="width: 100%;height:100%"/>
              </div>
            </div>
            <span style="font-size: xx-small">注：尺寸100*100，大小不超过500kb</span>
          </div>
        </a-form-model-item>

        <a-form-model-item label="类别" prop="type">
        <!-- <a-radio-group :options="plainOptions" :default-value="this.expertForm.type === null?value1:value2" @change="onChange1" /> -->
        <a-radio-group :options="plainOptions" :value="this.yihutype === '医护人员'?value1:value2" @change="onChange1"/>
        <!-- <a-radio-group :options="plainOptions" :default-value="value2" @change="onChange1"/> -->
        </a-form-model-item>
        <a-form-model-item label="省份城市" prop="province" v-if="this.yihutype == '医护人员'">
          <a-cascader
              v-model="addForm.province"
              :options="hospitaloPtions"
              placeholder="请选择"
              @change="provinceChange"
          />
        </a-form-model-item>
        <a-form-model-item label="省份城市" prop="province2" v-show="false">
          <a-cascader
              v-model="addForm.province2"
              :options="hospitaloPtions"
              placeholder="请选择"
              @change="provinceChange"
          />
        </a-form-model-item>

        <!-- 1 -->
        <a-form-model-item label="医院" prop="hospital" v-if="this.yihutype == '医护人员' ||this.yihutype == undefined ">
          <a-select
              v-model="addForm.hospital"
              placeholder="请选择"
              show-search
              @popupScroll="popupScroll_questionCode"
              @search="select_hospital"
              >
            <a-select-option
                v-for="item in hospitalNameList"
                :value="item.name">
              {{item.name}}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="单位/学校" prop="hospital2" v-if="this.yihutype == '非医护人员/医学生'">
        <a-input v-model="addForm.hospital2" auto-size placeholder="请输入" />
        </a-form-model-item>


        <!-- 2 -->
        <a-form-model-item label="科室" prop="department" v-if="this.yihutype == '医护人员' ||this.yihutype == undefined ">
          <a-cascader
              v-model="addForm.department"
              :options="hospitalOfficesList"
              placeholder="请选择"
              />
        </a-form-model-item>
        <a-form-model-item label="部门" prop="department2" v-if="this.yihutype == '非医护人员/医学生'">
          <a-input v-model="addForm.department2"  auto-size placeholder="请输入" />
        </a-form-model-item>

        <!-- 3 -->
        <a-form-model-item label="职称"  prop="job" v-if="this.yihutype == '医护人员' ||this.yihutype == undefined ">
          <a-select
              v-model="addForm.job"
              placeholder="请选择"
          >
            <a-select-option
                v-for="item in jobTitleList"
                :value="item.jobTitle">
              {{item.jobTitle}}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="职位"  prop="job2" v-if="this.yihutype == '非医护人员/医学生' ">
          <a-input v-model="addForm.job2" placeholder="请输入"/> 
        </a-form-model-item>


      <a-form-model-item label="标签" >
        <div>
          <a-input
              v-if="tagsShow"
              ref="input"
              type="text"
              size="small"
              :style="{ width: '78px' }"
              :value="inputValue"
              @change="handleInputChange"
              @blur="handleInputConfirm('add')"
              @keyup.enter.native="$event.target.blur()"
          />
          <a-tag  v-else style="background: #fff; borderStyle: dashed;" @click="showInput">+标签</a-tag>
        </div>
        <div>
          <a-tag v-for="(list,index) in addForm.tags" >{{ list }}<a-icon type="close" @click="delTags(index,'add')"/></a-tag>
        </div>


      </a-form-model-item>


      <a-form-model-item  label="简介"  prop="into">
        <a-textarea id="textareas" v-model="addForm.into" auto-size placeholder="请输入" style="min-height: 100px;"/>
      </a-form-model-item>
    </a-form-model>


    <div
        :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
          zIndex: 1,
        }"
    >
      <a-button :style="{ marginRight: '8px' }" @click="()=>this.addShow=false">
        取消
      </a-button>
      
      <!--新增  -->
      <a-button type="primary" @click="addOk">
        确认
      </a-button>
    </div>
  </a-drawer>

  <!-- 编辑的 -->
  <a-drawer
      title="编辑"
      :width="600"
      :visible="editShow"
      :body-style="{ paddingBottom: '80px' }"
      @close="()=>this.editShow=false"
  >
    <a-form-model ref="editItem" :label-col="labelCol" :model="editForm" :rules="editRules" :wrapper-col="wrapperCol"  >
      <a-form-model-item label="姓名 " prop="name">
        <!--        <a-auto-complete @blur="nameBulr('editForm')" @change="nameChange($event, 'editForm')" v-model="editForm.name" placeholder="请输入" @search="handleSearch($event, 'editForm')">-->
        <!--          <template slot="dataSource">-->
        <!--            <a-select-option @click="changeClick(email, 'editForm')" v-for="(email, index) in result" :key="JSON.stringify({name: email.name, id: email.id})">-->
        <!--              {{ email.nameAndHospital }}-->
        <!--            </a-select-option>-->
        <!--          </template>-->
        <!--        </a-auto-complete>-->
        <a-input v-model="editForm.name" placeholder="请输入" />
      </a-form-model-item>
      <a-form-model-item label="头像1" prop="headImg">
        <div>
          <div style="display: flex">
            <div class="uploadImg">
              <input
                  id="inputClear3"
                  ref="editImg"
                  accept="image/*"
                  style="width: 100%;height:100%;opacity: 0;"
                  type="file"
                  @change="uploadImg('editImg')"/>
            </div>
            <div class="upload_gutter">
              <img
                  v-if="editForm.headImg"
                  :src="editForm.headImg"
                  alt=""
                  style="width: 100%;height:100%"/>
            </div>
          </div>
          <span style="font-size: xx-small">注：尺寸100*100，大小不超过500kb</span>
        </div>
      </a-form-model-item>
      <a-form-model-item label="头像2">
        <div>
          <div style="display: flex">
            <div class="uploadImg">
              <input
                  id="inputClear4"
                  ref="editImgRect"
                  accept="image/*"
                  style="width: 100%;height:100%;opacity: 0;"
                  type="file"
                  @change="uploadImg('editImgRect')"/>
            </div>
            <div class="upload_gutter2">
              <img
                  v-if="editForm.headImgRect"
                  :src="editForm.headImgRect"
                  alt=""
                  style="width: 100%;height:100%"/>
            </div>
          </div>
          <span style="font-size: xx-small">注：尺寸100*100，大小不超过500kb</span>
        </div>
      </a-form-model-item>

      <a-form-model-item label="类别" prop="type">
      <!-- <a-radio-group :options="plainOptions" :default-value="this.expertForm.type === null?value1:value2" @change="onChange1" /> -->
      <a-radio-group :options="plainOptions" :value="this.yihutype === '医护人员'?value1:this.yihutype === null?value1:value2" @change="onChange1"/>
      <!-- <a-radio-group :options="plainOptions" :default-value="value2" @change="onChange1"/> -->
      </a-form-model-item>
      <a-form-model-item label="省份城市" prop="province" v-if="this.yihutype == '医护人员' || this.yihutype == null">
        <a-cascader
            v-model="editForm.province"
            :options="hospitaloPtions"
            placeholder="请选择"
            @change="provinceChange"
        />
      </a-form-model-item>
      <a-form-model-item label="省份城市" prop="province2" v-show="false">
        <a-cascader
            v-model="editForm.province2"
            :options="hospitaloPtions"
            placeholder="请选择"
            @change="provinceChange"
        />
      </a-form-model-item>
      <a-form-model-item label="医院" prop="hospital" v-if="this.yihutype == '医护人员' || this.yihutype == null">
        <a-select
            v-model="editForm.hospital"
            placeholder="请选择"
            show-search
            @popupScroll="popupScroll_questionCode"
            @search="select_hospital"
        >
          <a-select-option
              v-for="item in hospitalNameList"
              :value="item.name">
            {{item.name}}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="单位/学校" prop="hospital2" v-if="this.yihutype == '非医护人员/医学生' || this.yihutype == '非医护人员'">
        <a-input v-model="editForm.hospital2" auto-size placeholder="请输入" />
      </a-form-model-item>
      <a-form-model-item label="科室" prop="department" v-if="this.yihutype == '医护人员' || this.yihutype == null">
        <a-cascader
            v-model="editForm.department"
            :options="hospitalOfficesList"
            placeholder="请选择"
        />
      </a-form-model-item>
      <a-form-model-item label="部门" prop="department2" v-if="this.yihutype == '非医护人员/医学生' || this.yihutype == '非医护人员' ">
        <a-input v-model="editForm.department2"  auto-size placeholder="请输入" />
      </a-form-model-item>

      <a-form-model-item label="职称"  prop="job" v-if="this.yihutype == '医护人员'  || this.yihutype == null">
        <a-select
            v-model="editForm.job"
            placeholder="请选择"
        >
          <a-select-option
              v-for="item in jobTitleList"
              :value="item.jobTitle">
            {{item.jobTitle}}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="职位"  prop="job2" v-if="this.yihutype == '非医护人员/医学生' || this.yihutype == '非医护人员'">
        <a-input v-model="editForm.job2" placeholder="请输入"  />
      </a-form-model-item>

      <a-form-model-item label="标签" >
        <div>
          <a-input
              v-if="tagsShow"
              ref="input"
              type="text"
              size="small"
              :style="{ width: '78px' }"
              :value="inputValue"
              @change="handleInputChange"
              @blur="handleInputConfirm('edit')"
              @keyup.enter.native="$event.target.blur()"
          />
          <a-tag  v-else style="background: #fff; borderStyle: dashed;" @click="showInput">+标签</a-tag>
        </div>
        <div>
          <a-tag v-for="(list,index) in editForm.tags" >{{ list }}<a-icon type="close" @click="delTags(index,'edit')"/></a-tag>
        </div>


      </a-form-model-item>
      <a-form-model-item  label="简介"  prop="into" v-show="false">
        <a-textarea v-model="editForm.into" auto-size placeholder="请输入" style="min-height: 100px"/>
      </a-form-model-item>
    </a-form-model>


    <div
        :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
          zIndex: 1,
        }"
    >
      <a-button :style="{ marginRight: '8px' }" @click="()=>this.editShow=false">
        取消
      </a-button>

      <a-button type="primary" @click="editOk">
        确认
      </a-button>
<!--      <a-popconfirm title="是否确认修改？" ok-text="是" cancel-text="否" @confirm="editOk">  -->
<!--      </a-popconfirm>-->

    </div>
  </a-drawer>

  <!-- 绑定账号 -->
  <a-drawer
      title="绑定账号"
      width="900"
      :visible="scopeShow"
      @close="()=>{this.scopeShow = false;this.memberLogName='';this.memberLogTel='';this.telData=[]}"
  >
    <div class="selectPersonnel" >
      <div style="display: flex;align-items: center;width: 100%;justify-content: space-between;margin: 5px 0 5px 0">
        <a-form-model ref="addItem" :label-col="labelCol" :model="memberForm"  :wrapper-col="wrapperCol"  >
          <span style="display: flex">
            <a-form-model-item label="姓名" >
            <a-input v-model="memberForm.name" style="width: 200px;margin-right: 10px" disabled  />
          </a-form-model-item>
            <a-form-model-item label="医院" >
            <a-input v-model="memberForm.hospital" style="width: 200px;margin-right: 10px" disabled placeholder="请输入" />
          </a-form-model-item>
          </span>
          <span style="display: flex">
            <a-form-model-item label="职称" >
            <a-input v-model="memberForm.job" style="width: 200px;margin-right: 10px" disabled placeholder="请输入" />
          </a-form-model-item>
            <a-form-model-item label="科室" >
            <a-input v-model="memberForm.hospitalOffice!==null?memberForm.hospitalOffice:memberForm.department" style="width: 200px;margin-right: 10px" disabled placeholder="请输入" />
          </a-form-model-item>
          </span>
          <span style="display: flex">
            <a-form-model-item label="状态" >
            <a-input v-model="memberForm.state" :style="{'color': memberForm.state==='未绑定'?' #CC0000':'#00CC33'}"   style="width: 200px" disabled placeholder="请输入" />
          </a-form-model-item>
          </span>

        </a-form-model>
      </div>
    </div>
    <div class="selectedStaff" style="margin: 10px 0 40px;">
      <div style="display: flex;justify-content: space-between">
        <h3>{{memberForm.memberId===null?'查询注册信息':'注册信息'}}</h3>
      </div>
<!--      <div style="display: flex;align-items: center;width: 60%;justify-content: space-between;margin: 5px 0 5px 0">-->
<!--        <div> 姓名: &nbsp;<a-input v-model="memberLogName" style="width:160px" placeholder="请输入 " @keyup.enter.native="searchLogTel()"/></div>-->
<!--        <div> 手机号: &nbsp;<a-input v-model="memberLogTel" style="width:160px" placeholder="请输入 " @keyup.enter.native="searchLogTel()"/></div>-->
<!--        <a-button type="primary"  @click="searchLogTel"> 查询</a-button>-->
<!--      </div>-->
      <a-table :columns="memberColumn" :data-source="telData" :rowKey="(record,index)=>{return index}"   :pagination="false"
      >
            <span slot="operate" slot-scope="text,item">
              <a-popconfirm
                  title="确定解绑?"
                  ok-text="是"
                  cancel-text="否"
                  @confirm="delMember(item.id)"
                  @cancel="cancel"
                  v-if="memberForm.memberId!==null &&memberForm.memberId!=='null'"
              >
                <a>解绑</a>
              </a-popconfirm>
              <a v-else  @click="memberOk(item.id)">选择</a>
            </span>
      </a-table>
    </div>
    <div
        :style="{
          position: 'absolute',
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e8e8e8',
          padding: '10px 16px',
          textAlign: 'right',
          left: 0,
          background: '#fff',
          borderRadius: '0 0 4px 4px',
        }"
    >
      <a-button style="marginRight: 8px" @click="()=>{this.scopeShow = false;this.memberLogName='';this.memberLogTel='';this.telData=[]}">
        取消
      </a-button>
      <a-button type="primary"
                @click="()=>{this.scopeShow = false;this.memberLogName='';this.memberLogTel='';this.telData=[]}">
        确认
      </a-button>
    </div>
  </a-drawer>

  <!-- 附件 -->
  <a-drawer
      title="附件"
      width="600"
      :visible="accessoryShow"
      @close="()=>{this.accessoryShow = false}"
  >
    <div class="selectPersonnel" >
      <div style="display: flex;justify-content: space-between">
        <h4>上传附件</h4>
      </div>
      <a-form-model ref="accessorAdd" :label-col="labelCol" :model="accessorForm" :rules="rules" :wrapper-col="wrapperCol"  >
        <a-form-model-item label="备份附件" prop="enclosure">
          <div class="upload_File">
            <input style=" cursor:pointer;width:100%;height: 100%;opacity: 0;" ref="addFile" type="file"  @change="addFile"  />
          </div>
          <span style="font-size: xx-small">{{accessorForm.fileName}}</span>
        </a-form-model-item>
        <a-form-model-item label="备份名称" prop="name">
          <a-input v-model="accessorForm.name" placeholder="请输入" />
        </a-form-model-item>
      </a-form-model>
      <div style="display: flex;justify-content: center">
        <a-button  type="primary" @click="accessorAddOk">提交</a-button>
      </div>
    </div>
    <div class="selectedStaff" style="margin: 10px 0;">
      <div style="display: flex;justify-content: space-between">
        <h4>附件列表</h4>
      </div>
      <a-table :columns="accessoryColumn" :data-source="accessoryData" :rowKey="(record,index)=>{return index}"   :pagination="false"
      >
            <span slot="operate" slot-scope="text,item">
              <a @click="downAccessory(item.enclosure)">下载</a>
            </span>
      </a-table>
    </div>
  </a-drawer>

  <!-- 简介 -->
  <a-drawer
      title="简介"
      width="600"
      :visible="resumeShow"
      @close="()=>{this.resumeShow = false}"
  >
    <a-form-model ref="addItem" :label-col="labelCol" :rules="rules" :wrapper-col="wrapperCol" :model="formData" >
      <a-form-model-item label="简介" prop="into">
          <a-textarea auto-size placeholder="请输入" v-model="formData.into" style="min-height: 400px;font-size: 16px"/>
          <span style="color: #8c8a8a;font-size: 12px">简介上次修改时间: {{formData.upUpdatedTime ? formData.upUpdatedTime : formData.createdTime}}</span>
      </a-form-model-item>
    </a-form-model>
    <div
        :style="{
          position: 'absolute',
          right: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
          zIndex: 1,
        }"
    >
      <a-button :style="{ marginRight: '8px' }" @click="()=>{
        this.expertForm.formShow = false;
        this.formData={
          tags:[],
          hospital:undefined,
        }
      }">
        取消
      </a-button>
       <a-popconfirm title="是否确认更新简介" ok-text="是" cancel-text="否" @confirm="updateOk"> 
         <a-button type="primary" v-show="expertForm.formType !=='add'" :style="{ marginRight: '8px' }" >
           确认
         </a-button>
      </a-popconfirm>

    </div>
  </a-drawer>

  <a-modal
      :zIndex="1005"
      title="位置修改填写"
      :visible="wzVisible"
      @ok="wzOk"
      @cancel="() => wzVisible = false"
  >
    <div style="font-size: 13px;">
      示例：如专家原医院由山东省立医院改为齐鲁医院，内容则输入：齐鲁医院    </div>
    <a-form-model ref="wzItem" :rules="wzRules"         :label-col="{ span: 6 }"
                  :wrapper-col="{ span: 18 }" :model="wzData" >
      <a-form-model-item style="margin-top: 20px" label="填写修改后内容" prop="laterContent">
        <a-textarea auto-size placeholder="请输入" v-model="wzData.laterContent" style="width:100%;min-height: 120px;font-size: 16px"/>
<!--        <span style="color: #8c8a8a;font-size: 12px">简介上次修改时间: {{formData.upUpdatedTime ? formData.upUpdatedTime : formData.createdTime}}</span>-->
      </a-form-model-item>
    </a-form-model>
<!--    <template slot="footer">-->
<!--      <a-button  @click="() => wzVisible = false">-->
<!--        取消-->
<!--      </a-button>-->
<!--      <a-button key="submit" type="primary" @click="wzOk">-->
<!--        确定-->
<!--      </a-button>-->
<!--    </template>-->
  </a-modal>

</div>
</template>

<script>
import City from "@/assets/city.json";
import {getHospital} from "@/service/hospital_api";
import {update} from "@/utils/update";
import {getLoginDepartment} from "@/service/dataCleaned_api";
import {getTitle, getTitle2} from "@/service/dataDictionary_api";
// import AddExpertModel from "@/utils/AddExpertModel";
import {
  delExpert,
  getExpert, getExpertAccessor,
  getMeetMemberTel,
  postExpert, postExpertAccessor, postExpertFile,
  postMeetExpert,
  putExpert,
  putExpertMember,
  getExpertId, getExpertApi, postExpertAccessorPut
} from "@/service/zoomMeet_api";
import {postUpCover} from "@/service/image_api";
import { getCommunictionList } from "@/service/liveMeet_api";
import moment from "moment";
const plainOptions = ['医护人员', '非医护人员/医学生'];


export default {
  // components: {AddExpertModel},
  name: "Expert",
  data(){
    let addImageValid = (rule, value, callback) => { // 自定义校验规则-新增图片
      if (this.addForm.name) {
        callback();
      }else {
        callback(new Error('请输入姓名'));
      }
    };    let editImageValid = (rule, value, callback) => { // 自定义校验规则-新增图片
      if (this.editForm.name) {
        callback();
      }else {
        callback(new Error('请输入姓名'));
      }
    };
    return {
      wzRules: {
        laterContent: [{required: true, message: '请输入', trigger: 'blur'}],
      },
      rules: {
        // laterContent: [{required: true, message: '请输入', trigger: 'blur'}],
      },
      wzData: {
        laterContent: ''
      },
      wzVisible: false,
      plainOptions,
      formData:{
        hospital:undefined,
        tags:[]
      },
      value1: '医护人员',  //用与医护类型的默认框
      value2: '非医护人员/医学生',
      yihutype:'医护人员',   //初始值一定要设置医护人员
      // isConsultation:undefined,
      expertForm: {
        // type: null,
        formShow:false,
        formType: undefined,
        type: undefined,
      },
      City:City,
      addShow: false,
      editShow: false,
      tagsShow:false,
      scopeShow:false,
      accessoryShow: false,
      resumeShow:false,
      inputValue: '',
      addForm: {
        job2: "",
        hospital2: '',  //表单传空值的时候做一个初始值
        department2: "", 
        province2:[],
        tags: [],
      },
      editForm: {
        department2: '',
        job2: '',
        province2:'',
      },
      telData:[],
      memberForm:{},
      hospitaloPtions:[],
      hospitalNameList:[],
      jobTitleList:[],
      hospitalOfficesList:[],
      addRules: {
        name: [{ validator:addImageValid,required: true, trigger: 'blur'}],
        province: [{ required: true, message: '请选择省份/城市', trigger: 'change' }],
        headImg: [{required: true, message: '请上传头像', trigger: 'blur'}],
        hospital: [{ required: true, message: '请选择医院', trigger: 'change' }],
        hospital2: [{required: true, message: '请输入单位', trigger: 'change'}],
        department: [{required: true, message: '请选择科室', trigger: 'change'}],
        into: [{required: true, message: '请输入简介', trigger: 'blur'}],
        job: [{required: true, message: '请选择职称', trigger: 'change'}],
        enclosure: [{required: true, message: '请上传文件', trigger: 'blur'}],
      },
      editRules: {
        name: [{ validator:editImageValid,required: true, trigger: 'blur'}],
        province: [{ required: true, message: '请选择省份/城市', trigger: 'change' }],
        headImg: [{required: true, message: '请上传头像', trigger: 'blur'}],
        hospital: [{ required: true, message: '请选择医院', trigger: 'change' }],
        hospital2: [{required: true, message: '请输入单位', trigger: 'change'}],
        department: [{required: true, message: '请选择科室', trigger: 'change'}],
        into: [{required: true, message: '请输入简介', trigger: 'blur'}],
        job: [{required: true, message: '请选择职称', trigger: 'change'}],
        enclosure: [{required: true, message: '请上传文件', trigger: 'blur'}],
      },
      searchName:undefined,
      InfoTable:[],
      columns:[
        {
          title: 'icon',
          width:'5%',
          scopedSlots: {customRender: 'icon'},
        },
        {
          title: '名称',
          width: '40%',
          scopedSlots: {customRender: 'titles'},
        },
        {
          title: '创建时间',
          width: '25%',
          scopedSlots: {customRender: 'time'},
        },
        {
          title: '认证状态',
          width: '20%',
          scopedSlots: {customRender: 'claimStatus'},
        },
        {
          title: '专家',
          width: '10%',
          scopedSlots: {customRender: 'open'},
        },
        {
          title: '操作',
          fixed: 'right',
          scopedSlots: {customRender: 'operate'},
        },
      ],
      memberData:[],
      memberColumn:[
          {
        title: '姓名',
        dataIndex: 'name',
      },
        {
          title: '手机',
          dataIndex: 'tel',
        },
        {
          title: '医院',
          dataIndex: 'hospitalName',
        },
        {
          title: '科室',
          dataIndex: 'hospitalOffices',
        },
        {
          title: '职称',
          dataIndex: 'jobTitle',
        },
        // {
        //   title: '操作',
        //   scopedSlots: {customRender: 'operate'},
        // },
      ],
      accessoryData:[],
      accessorForm:{},
      accessoryColumn:[
        {
          title: '备份名称',
          dataIndex: 'name',
        },
        {
          title: '上传人',
          dataIndex: 'createdByName',
        },
        {
          title: '上传时间',
          dataIndex: 'createdTime',
        },
        {
          title: '操作',
          scopedSlots: {customRender: 'operate'},
        },],
      memberLogName:'',
      memberLogTel:'',
      labelCol: {span: 4},
      wrapperCol: {span: 16},
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10,
      },
      hospitalPage:{
        name:undefined,
        current: 1,
      },
      result: [],
      expertId: null,
      editData: {},
      editStatus: null
    }
  },

    watch: {
    expertForm(newVal, oldVal) {
      this.hospitalNameList = []
      this.getPCH()
      this.getHospitalOfficesList()
      this.getJobTitleList()
      console.log("看看newVal",newVal)
      // if (newVal.formType === 'edit' || newVal.formType === 'resume') {
        this.getExpertData(newVal.editId)
      // } else {
        this.getPCH()
        this.formData={
          tags:[],
          hospital:undefined,
        // }
      }
    },

  },

  mounted() {
    this.getLogData()
  },
  methods: {
    async wzOk () {
      this.$refs.wzItem.validate(async valid => {
        if (valid) {
          const edit = await putExpert(this.editData)
          if (edit.code===0){
            this.$message.success('修改成功')
            if (this.editStatus === 'add') {
              this.addForm={
                tags:[]
              }
              this.getLogData()
            } else if (this.editStatus === 'edit'){
              this.editForm={}
              // this.editShow = false
              this.getLogData()
            }
            const data = {
              expertId: this.expertId,
              laterContent: this.wzData.laterContent
            }
            const res = await postExpertAccessorPut(data)
            if (res.code === 0) {
              this.$message.success('填写成功')
              if (this.editStatus === 'add') {
                this.addShow = false
              } else if (this.editStatus === 'edit') {
                this.editShow = false
              }
              this.wzData.laterContent = ''
              this.wzVisible = false
            } else {
              this.$message.warning(res.message)
            }
            // this.wzVisible = true
          }

        }
      })

    },
    nameBulr (type) {
      console.log(this[type])
    },
    nameChange (e, type) {
      if (typeof JSON.parse(e) === "object") {
        this[type].name = JSON.parse(e).name
      } else {
        this[type].name = e
      }
    },
    changeClick (row, type) {
      this.getPCH()
      this.getHospitalOfficesList()
      this.getJobTitleList()
      this[type] = JSON.parse(JSON.stringify(row))
      this.yihutype = row.type
      this.expertId = row.id
      if (row.type == '医护人员' || row.type == null) {
        // this[type].department = this[type].department !== null ? this[type].department.split('-') : this[type].hospitalOffice !== null ? this[type].hospitalOffice.split('-') : []
        // this[type].department = row.hospitalOffice !== null ? row.hospitalOffice.split('-') : []
        this[type].department = row.hospitalOffice !== null ? row.hospitalOffice.split('-') : [row.department]
        this[type].province = [this[type].province, this[type].city]
        if (this[type].tags===null){
          this[type].tags=[]
        }else {
          this[type].tags=this[type].tag
        }
      }
      if (row.type == '非医护人员/医学生' || row.type == '非医护人员') {
        // this[type].department = this[type].department !== null ? this[type].department.split('-') : this[type].hospitalOffice !== null ? this[type].hospitalOffice.split('-') : []
        // this[type].department2 = row.hospitalOffice"
        this[type].province2 = [this[type].province, this[type].city]
        this.$set(this[type], 'department2', row.hospitalOffice)
        this.$set(this[type], 'job2', row.job)
        this.$set(this[type], 'hospital2', row.hospital)
        if (this[type].tags===null){
          this[type].tags=[]
        }else {
          this[type].tags=this[type].tag
        }
      }
    },
    async getExperTsTabe (name) {
      const data = {
        name,
      }
      if (name.trim().length === 0) {
        return []
      }
      const res = await getExpertApi(data)
      if (res.code === 0) {
        return res.data ? res.data : []
      }
    },
    async handleSearch(value, type) {
      if (type === 'addForm' && this[type].id) this.$refs.addItem.resetFields()
      let result;
      const name = this.result.filter(item => item.id == value)[0]
      if (name) value = name.name
      result = await this.getExperTsTabe(value)
      this.result = result;
      this[type].name = value
    },
    addClose() {
      this.addShow = false;
      this.$refs.addItem.resetFields() 
      this.$set(this.addForm, 'headImgRect', "") 
      this.$set(this.addForm, 'province', undefined)
    },
    onChange1(e) {
      this.yihutype = e.target.value
      // 切换的时候清空绑定的值
      this.$set(this.editForm, 'department2', "")
      this.$set(this.editForm, 'department', "")
      this.$set(this.editForm, 'job2', "")
      this.$set(this.editForm, 'job', undefined)
      this.$set(this.editForm, 'hospital', undefined)
      this.$set(this.editForm, 'hospital2', undefined)
      this.$set(this.editForm, 'province', undefined)

      this.$set(this.addForm, 'department2', "")  //转换的时候不要设置undefined
      this.$set(this.addForm, 'department', "")
      this.$set(this.addForm, 'job2', "") 
      this.$set(this.addForm, 'job', "")   //这里还需要修改
      this.$set(this.addForm, 'hospital', undefined)
      this.$set(this.addForm, 'hospital2', undefined)
      this.$set(this.addForm, 'province', undefined)
      // this.$refs.addItem.resetFields() 
      this.$refs['addItem'].clearValidate()
      this.$refs['editForm'].clearValidate()
    },
    //获取专家
    async getLogData(){
      const data={
        pageNum:this.pagination.current,
        memberId:this.type,
        name:this.searchName
      }
      const info = await  getExpert(data)
      if (info.code===0){
        this.InfoTable=info.data.rows
        this.pagination.total=info.data.count

        this.InfoTable.forEach((value,index)=>{
          if (value.tags!==null){
            value.tag=value.tags.split(/[，；,;、]/)
          }
        })
      }
    },
    // 点击编辑，获取表单数据
    async getExpertData(id){
      const info = await getExpertId(id)
      console.log("总数据", info)
      if (info.code === 0) {
        this.formData = info.data.rows[0]
        // 通过这里赋值给department （department默认为空）
        if (this.type == "医护人员") {
          this.formData.department = info.data.rows[0].hospitalOffice !== null ? info.data.rows[0].hospitalOffice.split('-') : []
        } else {
          this.formData.departments = info.data.rows[0].hospitalOffice 
          this.formData.jobs = info.data.rows[0].job
        }

        this.formData.province=[this.formData.province,this.formData.city]
        let data = {
          province:this.formData.province[0],
          city:this.formData.province[1],
          name:this.formData.hospital,
          pagenum:1,
          pagesize:10,
        }

        await this.getHosNameList(data)

        if (this.formData.tags===null){
          this.formData.tags=[]
        }else {
          this.formData.tag=[]
          this.formData.tag=this.formData.tags.split(/[，；,;、]/)
          this.formData.tags=[]
          this.formData.tags=this.formData.tag
        }
      }
    },

    // async getExpertData(id){
    //   const info = await getExpertId(id)
    //   console.log("总数据", info)
    //   if (info.code === 0) {
    //     this[type] = info.data.rows[0]
    //     console.log("type",this.yihutype)
    //     // 通过这里赋值给department （department默认为空）
    //     if (this.yihutype == "医护人员" || this.yihutype == null) {
    //       this[type].department = info.data.rows[0].hospitalOffice !== null ? info.data.rows[0].hospitalOffice.split('-') : []
    //     } else if (this.yihutype == "非医护人员/医学生") {
    //       this[type].hospital2 = info.data.rows[0].hospital 
    //       this[type].department2 = info.data.rows[0].hospitalOffice 
    //       this[type].job2 = info.data.rows[0].job
    //     }

    //     this[type].province=[this[type].province,this[type].city]
    //     let data = {
    //       province:this[type].province[0],
    //       city:this[type].province[1],
    //       name:this[type].hospital,
    //       pagenum:1,
    //       pagesize:10,
    //     }

    //     await this.getHosNameList(data)

    //     if (this[type].tags===null){
    //       this[type].tags=[]
    //     }else {
    //       this[type].tag=[]
    //       this[type].tag=this[type].tags.split(/[，；,;、]/)
    //       this[type].tags=[]
    //       this[type].tags=this[type].tag
    //     }
    //   }
    // },

    //获取公司人员列表
    async getPerList(departmentById) {
      const response = await getCommunictionList(1,9999,1,'',departmentById)
      if(response.code === 0){
        this.leaderOperateList = response.data.rows
      }
    },

    getPCH(){
      let province = []
      this.City.forEach((item,index)=>{
        let city = []
        province.push({
          value:item.name,
          label:item.name,
          children:city
        })
        item.cityList.forEach((list)=>{
          city.push({
            value:list.name,
            label:list.name,
            loading:false,
          })
        })
        province[index].children = city
      })
      this.hospitaloPtions = province
    },
    //医院数据
    async getHosNameList(data) {
      const response = await getHospital(data)
      if(response.code === 0){
        let list = response.data.rows
        for (let i=0;i<list.length;i++){
          this.hospitalNameList.push({
            id: list[i].id,
            name: list[i].name,
          })
        }
      }
    },

    // async updateOk() {
    //   const form = this.formData
    //   const user_info = window.localStorage.getItem("user_info");
    //   console.log(form)
    //   const editData = {
    //     id: form.id,
    //     byId: JSON.parse(user_info).id,
    //     upUpdatedTime: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
    //     type: this.type,
    //     id:form.id,
    //     byId:JSON.parse(user_info).id,
    //     headImg:form.headImg,
    //     name:form.name,
    //     hospital:form.hospital,
    //     headImgRect: form.headImgRect,

    //     into: form.into,

    //     // tags:form.tags.length===0?null:form.tags.join(","),
    //     // province:form.province[0],
    //     // city:form.province[1],
    //     job:form.job,
    //     // department: form.department.join("-"),
    //     headImg:form.headImg,
    //     name:form.name,
    //     hospital:form.hospital,
    //     headImgRect:form.headImgRect,
    //     into:form.into,
    //     // tags:form.tags.length===0?null:form.tags.join(","),
    //     // province:form.province[0],
    //     // city:form.province[1],
    //     job:form.job,
    //     // department:form.department.join("-"),
    //   }
    //   const edit = await putExpert(editData)
    //   // 时间
    //   if(edit.code === 0){
    //     const info = await  getExpertId(form.id)
    //     this.formData.upUpdatedTime = info.data.rows[0].upUpdatedTime
    //     this.$message.success('提交成功')
    //     // console.log(info.data.rows[0].upUpdatedTime)
    //   } else {
    //     console.log("")
    //   }
    // },
    async updateOk() {
      const form = this.formData
      const user_info = window.localStorage.getItem("user_info");
      const editData = {
        id: form.id,
        byId: JSON.parse(user_info).id,
        upUpdatedTime: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),

        // headImg:form.headImg,
        // name:form.name,
        // hospital:form.hospital,
        // headImgRect:form.headImgRect,
        into:form.into,
        // tags:form.tags.length===0?null:form.tags.join(","),
        // province:form.province[0],
        // city:form.province[1],
        // job:form.job,
        // department:form.department,
      }
      const edit = await putExpert(editData)
      if(edit.code === 0){
        const info = await getExpertId(form.id)
        this.formData.upUpdatedTime = info.data.rows[0].upUpdatedTime
        this.getLogData()
        this.resumeShow = false
        this.$message.success('简介更新成功')
        // console.log(info.data.rows[0].upUpdatedTime)
      }
    },

    //滚动条监听
    popupScroll_questionCode(e){
      const {target} = e
      const scrollHeight = target.scrollHeight - target.scrollTop //滑动总高度
      const clientHeight = target.clientHeight //滑动最低高度
      if(scrollHeight < clientHeight + 2 && this.expertForm.formType == "add"){
        this.hospitalPage.current++
        let values = this.addForm.province
        const data={
          province:values[0],
          city:values[1],
          name:this.hospitalPage.name,
          pagenum:this.hospitalPage.current,
          pagesize:10,
        }
        this.getHosNameList(data)
        // this.getExamModel()
      }else if(scrollHeight < clientHeight + 2 && this.expertForm.formType == "edit"){
        this.hospitalPage.current++
        let values = this.editForm.province
        const data={
          province:values[0],
          city:values[1],
          name:this.hospitalPage.name,
          pagenum:this.hospitalPage.current,
          pagesize:10,
        }
        this.getHosNameList(data)
      }
    },
    // select_hospital(value){
    //   this.hospitalNameList = []
    //   this.hospitalPage.name=value
    //   const data={
    //     name:value,
    //     pagenum:1,
    //     pagesize:10,
    //   }
    //   this.getHosNameList(data)
    // },

    select_hospital(value){
      this.hospitalNameList = [] 
      if(this.expertForm.formType == "add"){
        this.hospitalPage.name=value
        let values = this.addForm.province
        const data={
          province:values[0],
          city:values[1],
          name:value,
          pagenum:1,
          pagesize:10,
        }
        this.getHosNameList(data)
      }else if(this.expertForm.formType == "edit"){
        this.hospitalPage.name=value
        let values = this.editForm.province
        const data={
          province:values[0],
          city:values[1],
          name:value,
          pagenum:1,
          pagesize:10,
        }
        this.getHosNameList(data)
      }
    },

    //省份城市数据
    provinceChange(value) {
      this.hospitalNameList = [] 
      this.$set(this.addForm, 'hospital', undefined)
      this.$set(this.editForm, 'hospital', undefined)
      this.hospitalPage.current = 1
      let data = {
        province:value[0],
        city:value[1],
        pagenum:1,
        pagesize:10,
      }
      this.getHosNameList(data)
    },
    async getJobTitleList(identify) {
      const response = await getTitle2(identify)
      if (response.code === 0) {
        this.jobTitleList=response.data.row
      }
    },
    //获取科室列表
    async getHospitalOfficesList() {
      const response = await getLoginDepartment()
      if(response.code === 0){
        let oneOff = []
        let list = response.data.wySy
        list.forEach((item,index)=>{
          let twoOff = []
          oneOff.push({
            value:item.title,
            label:item.title,
            children:twoOff
          })
          item.zoffices.forEach((lists)=>{
            twoOff.push({
              value:lists.title,
              label:lists.title,
            })
          })
          oneOff[index].children = twoOff
        })
        this.hospitalOfficesList = oneOff
      }
    },

    showInput() {
      this.tagsShow = true;
      this.$nextTick(function() {
        this.$refs.input.focus();
      });
    },
    handleInputChange(e) {
      this.inputValue = e.target.value;
    },
     handleInputConfirm(type) {
      if (this.inputValue!==''){
        switch (type){
          case 'add':
            this.addForm.tags.push(this.inputValue)
            break;
          case 'edit':
            this.editForm.tags.push(this.inputValue)
        }
        this.tagsShow = false;
        this.inputValue=''
      }else {
        this.$message.warning('请输入内容')
      }
    },

    searchBtn(){
      this.pagination.current=1
      this.getLogData()
    },
    searchChange(){
      this.type=undefined
      this.pagination.current=1
      this.searchName=undefined
      this.getLogData()
    },
    searchOk(e){
      switch (e.target.value){
        case 0:
          this.type=0
          break;
        case 1:
          this.type=1
          break;
        case 'all':
          this.type=undefined
          break;
      }
      this.getLogData()
    },
    pageChange(current){
      this.pagination.current=current
      this.getLogData()
    },
    async getSearchMember(name,tel,memberId){
      const info = await getMeetMemberTel(name,tel,memberId)
      if (info.code===0){
        this.telData=info.data
        if (info.data.length===0){
          this.$message.warn('查询为空')
        }
      }else {
        this.$message.warning(info.message)
      }
    },

    searchLogTel(){
      this.getSearchMember(this.memberLogName,this.memberLogTel)
      this.$set(this.memberForm,'memberId',null)
    },

    async memberOk(memberID){
      const data={
        expertId:this.memberForm.id,
        memberId:memberID
      }
      const add = await putExpertMember(data)
      if (add.code===0){
        this.$message.success('操作成功')
        this.memberForm.memberId=memberID
        this.getSearchMember('','',memberID)
        this.getLogData()
        this.$set(this.memberForm,'state','已绑定')
      }
    },

     memberBtn(row){
      this.memberForm=JSON.parse(JSON.stringify(row))
      this.scopeShow=true
      if (row.memberId!==null && row.memberId!=='null' && row.memberId!==''){
        this.getSearchMember('','',row.memberId)
        this.memberForm.state='已绑定'
      }else {
        this.memberForm.state='未绑定'
      }
    },
    //添加会诊专家
    async meetAdd(id){
      const data={
        expertId:id
      }
      const add = await  postMeetExpert(data)
      if (add.code===0){
        this.getLogData()
        this.$message.success('操作成功')
      }
    },
    //上传图片
    async uploadImg(type) {
      var inputClear = document.querySelector('#inputClear')
      var inputClear2 = document.querySelector('#inputClear2')
      var inputClear3 = document.querySelector('#inputClear3')
      var inputClear4 = document.querySelector('#inputClear4')
      let inputDOM = this.$refs[type].files[0];
      if(inputDOM.size <512000){
        const image =await update(inputDOM)
        if (image.code === 0){
          switch (type){
            case 'addImg':
              this.$set(this.addForm,'headImg',image.data.url)
              break;
            case 'addImgRect':
              this.$set(this.addForm,'headImgRect',image.data.url)
              break;
            case 'editImg':
              this.$set(this.editForm,'headImg',image.data.url)
              break;
            case 'editImgRect':
              this.$set(this.editForm,'headImgRect',image.data.url)
              break;
          }
          this.$message.success('上传成功')
          if (inputClear.value !== '') {
            inputClear.value = ''
          } else if (inputClear2.value !== '') {
            inputClear2.value = ''
          } else if (inputClear3.value !== '') {
            inputClear3.value = ''
          } else if (inputClear4.value !=='') {
            inputClear4.value =''
          }
        }
        else {
          this.$message.warning('上传失败'+ image.message)
        }
      }
      else {
        this.$message.warning('仅上传500k内图片')
      }
    },

    // async uploadImg(type) {
    //   var inputTest = document.querySelector('#inputTest')
    //   // type只是传一个状态而已，说但是不知道怎么说，我感觉就是需要刷新
    //   let inputDOM = this.$refs[type].files[0];
    //   console.log(this.$refs[type])
    //   if(inputDOM.size < 512000 ){
    //     const image = await update(inputDOM)
    //     // if (this.form.images.length > 9) {
    //     //   this.$message.warning('太多了')
    //     // }
    //     if (image.code === 0 && this.form.images.length < 9 ){
    //       switch (type){
    //         case 'addImg':
    //           this.form.images.push(image.data.url)
    //           // console.log(image.data.url)
    //           // console.log(this.form)
    //           break;
    //       }
    //       this.$message.success('上传成功')
    //     }
    //     else {
    //       this.$message.warning('最多只能上传9张图片')
    //     }
    //   }
    //   else{
    //     this.$message.warning('仅上传500k内图片')
    //   }
    //   inputTest.value =''
    // },




    //获取专家附件
    async getAccessory(id){
      const info = await getExpertAccessor(id)
      if (info.code===0){
        await this.getPerList()
        this.accessoryData=info.data
        this.accessoryData.forEach((value,index)=>{
          this.leaderOperateList.forEach((ids,idIndex)=>{
            if (value.createdById===ids.id){
              value.createdByName = ids.userName
            }
          })
        })
      }
    },
    accessoryBtn(id){
      this.getAccessory(id)
      this.accessorId=id
      this.accessoryShow=true
    },
    downAccessory(url){
      window.open(url)
    },
    async accessorAddOk(){
      this.$refs.accessorAdd.validate(async valid =>{
        if(valid) {
          const form=this.accessorForm
          const data={
            expertId:this.accessorId,
            name:form.name,
            enclosure:form.enclosure
          }
          const add = await postExpertAccessor(data)
          if (add.code===0){
            this.accessorForm.name=''
            this.accessorForm.enclosure=''
            this.accessorForm.fileName=''
            this.getAccessory(this.accessorId)
            this.$message.success('提交成功')
          }else {
            this.$message.warning('上传失败，请重试')
          }
        }else {
          this.$message.error("输入有空~")
          return false;
        }
      })
    },
    async addFile(){
      let inputDOM = this.$refs.addFile.files[0];
      let formData = new FormData()
      formData.append('file', inputDOM)
      const add = await postExpertFile(formData)
      if (add.code === 0) {
        this.$set(this.accessorForm,'enclosure',add.data[0])
        this.$set(this.accessorForm,'fileName',inputDOM.name)
        this.$set(this.accessorForm,'name',inputDOM.name)
        this.$refs.addFile.value=null
        this.$message.success('上传成功')
      } else {
        this.$message.error("上传失败");
      }
    },
    addBtn(){
      this.expertForm={
        formType:'add',
        formShow: true,
        type: null,
      }
      this.addShow = true

      this.getPCH()
      this.yihutype = '医护人员'

      // this.getHospitalOfficesList()
      // this.getJobTitleList()
    },
    addOk(){
      this.$refs.addItem.validate(async valid =>{
        if(valid){
          const user_info = window.localStorage.getItem("user_info");
          const form = this.addForm
          if (this.yihutype == '医护人员' || this.yihutype == undefined) {
            const data={
            byId:JSON.parse(user_info).id,
            headImg:form.headImg,
            name:form.name,
            hospital:form.hospital,
            headImgRect:form.headImgRect,
            into:form.into,
            tags:!form.tags || form.tags.length===0?null:form.tags.join(","),
            province:form.province[0],
            city:form.province[1],
            job:form.job,
            department: form.department[0] + '-' + form.department[1],
            type:'医护人员',
              memberId: form.memberId,
            }
            if (this.addForm.id) {
              data.id = this.addForm.id
              data.edit = 'edit'
              this.editData = data
              if (this.editStatus === 'edit') {
                this.wzData.laterContent = ''
              }
              this.editStatus = 'add'
              // this.addShow=false
              this.wzVisible = true
            } else {
              const add = await postExpert(data)
              if (add.code===0){
                this.addForm={
                  tags:[]
                }
                this.$message.success('新增成功')
                this.getLogData()
                this.addShow=false
              }
            }

          }
          if (this.yihutype == '非医护人员/医学生') {
            const data={
              byId:JSON.parse(user_info).id,
              headImg:form.headImg,
              name:form.name,
              hospital:form.hospital2,
              headImgRect:form.headImgRect,
              into:form.into,
              tags:!form.tags || form.tags.length===0?null:form.tags.join(","),
              province:"",
              city: "",
              hospitalOffice:form.hospitalOffice,
              job:form.job2,
              department: form.department2,
              type:this.yihutype,
              memberId: form.memberId

            }
            if (this.addForm.id) {
              data.id = this.addForm.id
              data.edit = 'edit'
              this.editData = data
              if (this.editStatus === 'edit') {
                this.wzData.laterContent = ''
              }
              this.editStatus = 'add'
              // this.addShow=false
              this.wzVisible = true
            } else {
              const add = await postExpert(data)
              if (add.code===0){
                this.addForm={
                  tags:[]
                }
                this.$message.success('新增成功')
                this.getLogData()
                this.addShow=false
              }
            }
          }

        }
      })
    },
    async editSUbmit (data) {
      const edit = await putExpert(data)
      if (edit.code===0){
        this.addForm={
          tags:[]
        }
        this.$message.success('修改成功')
        this.getLogData()
        this.addShow=false
      }
    },
    // 简介
    resumeBtn(row) {
      this.resumeShow = true
      // this.getAccessory(id)
      // this.accessorId=id
      // this.resumeShow = true
      // this.expertForm.formShow = true
      this.expertForm = {
        // type: row.type,
        editId: row.id,
        formType:'resume',
        formShow: true,
        type:row.type
      }
    },

    // 编辑框获取数据
     editBtn(row) {
      // console.log(this.rowType)
      // this.isConsultation = row.isConsultation
      this.expertForm = {
        // type: row.type,
        editId: row.id,
        formType:'edit',
        formShow: true,
        type:row.type
      }
      // 重新赋值
      // this.expertForm.type =this.expertForm.type
      this.getPCH()
      this.getHospitalOfficesList()
      this.getJobTitleList()
      this.editForm = JSON.parse(JSON.stringify(row))
      this.yihutype = row.type
      
      if (row.type == '医护人员' || row.type == null) {
        // this.editForm.department = this.editForm.department !== null ? this.editForm.department.split('-') : this.editForm.hospitalOffice !== null ? this.editForm.hospitalOffice.split('-') : []
        // this.editForm.department = row.hospitalOffice !== null ? row.hospitalOffice.split('-') : []
        this.editForm.department = row.hospitalOffice !== null ? row.hospitalOffice.split('-') : [row.department] 
        this.editForm.province = [this.editForm.province, this.editForm.city]
        if (this.editForm.tags===null){
          this.editForm.tags=[]
        }else {
          this.editForm.tags=this.editForm.tag
        }
      }
      if (row.type == '非医护人员/医学生' || row.type == '非医护人员') {
        // this.editForm.department = this.editForm.department !== null ? this.editForm.department.split('-') : this.editForm.hospitalOffice !== null ? this.editForm.hospitalOffice.split('-') : []
        // this.editForm.department2 = row.hospitalOffice"
        this.editForm.province2 = [this.editForm.province, this.editForm.city]
        this.$set(this.editForm, 'department2', row.hospitalOffice)
        this.$set(this.editForm, 'job2', row.job)
        this.$set(this.editForm, 'hospital2', row.hospital)
        if (this.editForm.tags===null){
          this.editForm.tags=[]
        }else {
          this.editForm.tags=this.editForm.tag
        }
      }
      this.editShow=true
    },

    
    async editOk(){
      this.$refs.editItem.validate(async valid =>{
        if(valid){
          const user_info = window.localStorage.getItem("user_info");
          const form = this.editForm
          this.expertId = form.id
          if (this.yihutype == '医护人员' || this.yihutype == null) {
            const data={
              id:form.id,
              byId:JSON.parse(user_info).id,
              headImg:form.headImg,
              name:form.name,
              hospital:form.hospital,
              headImgRect:form.headImgRect,
              into:form.into,
              tags:!form.tags || form.tags.length===0?null:form.tags.join(","),
              province:form.province[0],
              city:form.province[1],
              job:form.job,
              department: form.department.join("-"),
              type:"医护人员",
              memberId: form.memberId,
              edit: 'edit'
          }
          // const edit = await putExpert(data)
          // if (edit.code===0){
          //   this.editForm={}
          //   this.$message.success('修改成功')
          //   this.getLogData()
          //   this.editShow=false
            this.editData = data
            if (this.editStatus === 'add') {
              this.wzData.laterContent = ''
            }
            this.editStatus = 'edit'
            // this.editShow = false
            this.wzVisible = true
          //   }
          }

          if (this.yihutype == '非医护人员/医学生') {
            const data={
              id:form.id,
              byId:JSON.parse(user_info).id,
              headImg:form.headImg,
              name:form.name,
              hospital:form.hospital2,
              headImgRect:form.headImgRect,
              into:form.into,
              tags:!form.tags ||form.tags.length===0?null:form.tags.join(","),
              province:"",
              city:"",
              job:form.job2,
              department: form.department2,
              type:"非医护人员/医学生",
              memberId: form.memberId,
              edit: 'edit'
            }
            this.editData = data
            if (this.editStatus === 'add') {
              this.wzData.laterContent = ''
            }
            this.editStatus = 'edit'
            // this.editShow = false
            this.wzVisible = true
          // const edit = await putExpert(data)
          // if (edit.code===0){
          //   this.editForm={}
          //   this.$message.success('修改成功')
          //   this.getLogData()
          //   this.editShow = false
          //   this.wzVisible=  true
          // }
          }
        }
      })
    },
    //删除专家
    async delTitle(id){
      const Info =await delExpert(id)
      if (Info.code === 0){
        await this.getLogData()
        this.$message.success('操作成功')
      }else {
        this.$message.error('操作失败')
      }
    },
    //删除标签
    delTags(index,type){
      switch (type){
        case 'add':
          this.addForm.tags.splice(index, 1)
              break;
        case 'edit':
          this.editForm.tags.splice(index, 1)
              break;
      }
    },
    //置空绑定账号
    async delMember(){
      const data={
        expertId:this.memberForm.id,
        memberId:"0"
      }
      const del = await  putExpertMember(data)
      if (del.code===0){
        this.$message.success('操作成功')
        this.getLogData()
        this.$set(this.memberForm,'state','未绑定')
        this.memberForm.memberId=null
        this.telData=[]
      }
    },
    cancel(){
      this.$message.warning('取消操作')
    },
  },
}
</script>

<style lang="scss" scoped>

.expert{
  margin: -15px -15px;
  background-color: rgba(240, 242, 245, 1);
  .expert_header{
    background: white;
    height: 70px;
    display: flex;
    align-items: center;
    padding: 15px;
  }
  .expert_table{
    margin-top: 10px;
    padding: 15px;
    background: white;
  }
}
.uploadImg {
  border: #DDDDDD 2px dashed;
  width: 100px;
  height: 100px;
  background-image: url("../../../assets/add.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  cursor: pointer;
}
.upload_File{
  width: 120px;
  border: 1px #DDDDDD solid;
  border-radius:4px;
  height: 32px;
  background-image: url("../../../assets/add_imgfile.png");
  background-size:70% 70%;
  background-position:center center;
  background-repeat:no-repeat;
  cursor: pointer;
}
.upload_gutter {
  border: #DDDDDD 2px dashed;
  width: 100px;
  height: 100px;
  margin-left: 5px;
}

.upload_gutter2 {
  border: #DDDDDD 2px dashed;
  width: 100px;
  height: 100px;
  margin-left: 5px;
}
</style>
